@import "mixins";
@import "type";
@import "spacing";

%button-themes {
  &.theme--yellow {
    @include buttonTheme(var(--pale-peach));
  }
  &.theme--blue {
    @include buttonTheme(var(--eggshell-blue));
  }
  &.theme--pink {
    @include buttonTheme(var(--very-light-pink));
  }
}

%button {
  @extend %primary-button-text;
  background-color: transparent;
  border-radius: 30px;
  border: 2px solid var(--lime);
  color: var(--dark-green);
  display: inline-block;
  font-family: var(--sans);
  padding: 15px 20px;
  text-decoration: none;
  transition: all .3s;

  &:hover {
    background-color: var(--lime);
    color: var(--dark-green);
  }
}

%buttonWhite {
  @extend %button;
  color: var(--white);

  &:hover {
    background-color: var(--lime);
    color: var(--dark-green);
  }
}

%buttonDark {
  @extend %button;
  border-color: var(--dark-green);
  color: var(--dark-green);

  &:hover {
    background-color: var(--dark-green);
    color: var(--white);
  }
}
