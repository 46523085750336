@import "~scss/imports";

.TwoColumnBlock {
  @extend %max;
  padding-top: var(--pad);
  padding-bottom: var(--pad);

  &:first-of-type .TwoColumnBlock-wrapper {
    border: 0;
  }
}

.TwoColumnBlock-wrapper {
  border-top: 2px solid var(--lime);
  padding: 72px 0;
}

.TwoColumnBlock-heading {
  @extend %h3;
  margin-top: 0;
}

.TwoColumnBlock-copy {
  @extend %body;
  display: grid;
  grid-gap: var(--pad-x);
  padding-top: var(--pad);

  @include desktop {
    grid-template-columns: 1fr 1fr;
  }

  p {
    margin: 0;
  }
}