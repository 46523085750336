@import "~scss/imports";

.Faq {
  margin: unset;
}
.Faq-heading {
  @extend %h4;
}

.Faq-list {
  list-style-type: none;
  padding-left: 0;
  display: grid;
  grid-gap: 2em;
  counter-reset: list;
  margin-top: 2em;
}
.Faq-item-question {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 0;
  @extend %faq-question;
  text-align: left;
  display: flex;
  width: 100%;

  &:before {
    counter-increment: list;
    content: counter(list) ".";
    display: inline-block;
    padding-right: 0.5em;
  }

  svg {
    align-self: center;
    margin-left: auto;
    flex-shrink: 0;
    stroke: var(--marine);
    * {
      stroke: inherit;
    }
  }

  &.open svg {
    transform: rotate(180deg);
  }

  &:hover,
  &:focus {
    color: var(--dark-cerulean);
    svg {
      stroke: var(--dark-cerulean);
    }
  }
  &:focus {
    outline: 0;
  }
}
.Faq-item-question-text {
  margin-right: 1em;
}

.Faq-item-answer {
  margin-top: 1em;
  padding-left: calc(2ch + 0.5em);
  @extend %p;
}
