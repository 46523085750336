@import "~scss/imports";

@mixin compact {
  grid-template-areas: "heading heading" "image text" "link link";
  grid-template-areas:
    "image"
    "heading"
    "text"
    "link";
  grid-gap: 1em;

  .DetailedNavigation-item-image {
    // width: 160px;
    margin: auto;
  }
}

// DetailedNavigation

@mixin sideBySide {
  grid-template-columns: minmax(50%, 300px) 50%;
  grid-template-areas:
    "image ."
    "image heading"
    "image text"
    "image link"
    "image .";
  grid-gap: 1em 4vw;
  align-items: center;
}
@mixin stacked {
  grid-template-areas:
    "image"
    "heading"
    "text"
    "link";
  grid-template-columns: 1fr;
  grid-gap: 1em;
  grid-template-rows: auto auto auto 1fr;
  align-items: start;
}

.DetailedNavigation {
  list-style-type: none;

  padding-top: 4em;
  padding-bottom: 4em;
  margin-top: unset;
  margin-bottom: unset;

  // display: grid;
  // grid-gap: 4em;
}
.DetailedNavigation-item {
  display: grid;
  --max-text: 25em;

  @include tablet {
    --pad: 4vw;
  }
}
.DetailedNavigation--large {
  @extend %max;

  .DetailedNavigation-item {
    grid-template-areas:
      "image"
      "heading"
      "text"
      "link";
    grid-gap: 1em;

    padding-top: var(--pad);
    padding-bottom: var(--pad);

    @include tablet {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: calc(var(--pad) * 2);

      &:nth-child(odd) {
        grid-template-areas:
          ". image"
          "heading image"
          "text image"
          "link image"
          ". image";
        // grid-template-columns: 4fr 6fr;
      }

      &:nth-child(even) {
        grid-template-areas:
          "image ."
          "image heading"
          "image text"
          "image link"
          "image .";
        // grid-template-columns: 6fr 4fr;
      }

      grid-template-rows: 1fr auto auto auto 1fr;
      grid-row-gap: 1em;
    }
  }
}

.DetailedNavigation--medium {
  // display: grid;
  align-items: center;
  @extend %max;
  grid-gap: 4em;
  @include desktop {
    grid-template-columns: 1fr 1fr;
    grid-gap: 4vw;
  }
}
.DetailedNavigation--medium .DetailedNavigation-item {
  @include compact();
  @media (min-width: 520px) {
    @include sideBySide();
  }
}

.DetailedNavigation--small {
  // display: grid;
  grid-gap: 4em 4vw;
  @extend %max;
  @include desktop {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.DetailedNavigation--small .DetailedNavigation-item {
  .DetailedNavigation-item-image {
    width: 100%;
    // max-width: 300px;
  }
  @include compact;
  @media (min-width: 520px) {
    @include sideBySide();
  }
  @include desktop {
    @include stacked();
  }
}

.DetailedNavigation-item-image {
  grid-area: image;
  border-radius: var(--corner-radius);
  overflow: hidden;
}
.DetailedNavigation-item-heading {
  grid-area: heading;
  @extend %h2;
  max-width: var(--max-text);
}
.DetailedNavigation-item-text {
  grid-area: text;
  @extend %p;
  max-width: var(--max-text);
}
.DetailedNavigation-item-link {
  grid-area: link;
  margin-top: 0.8em;
}
