.MenuIcon {
  div {
    width: 100%;
    height: 2px;
    background-color: var(--cerulean);
    margin: 5px 0;
    transition: opacity 0.2s, transform 0.3s;
    transform-origin: center center;
  }

  &.active {
    div:nth-child(2) {
      opacity: 0;
    }
    div:nth-child(1) {
      transform: translate(0px, 8px) rotate(45deg);
    }
    div:nth-child(3) {
      transform: translate(0px, -6px) rotate(-45deg);
    }
  }
}
