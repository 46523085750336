.VideoEmbed {
  position: relative;
  width: 100%;
}

.VideoEmbed-wrapper {
  height: 0;
  position: relative;

  iframe {
    width: 100%;
    position: absolute;
    height: 100%;
  }
}

.VideoEmbed-placeholder {
  background-color: purple;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
