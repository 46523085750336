%max {
  max-width: 1440px;
  padding-left: var(--pad-x);
  padding-right: var(--pad-x);
  margin-left: auto;
  margin-right: auto;
}

%maxslim {
  max-width: 800px;
  padding-left: var(--pad-x);
  padding-right: var(--pad-x);
  margin-left: auto;
  margin-right: auto;
}

%headerpad {
  --pad: 2em;
  padding-top: var(--pad);
  padding-bottom: var(--pad);
  @include desktop {
    --pad: 80px;
  }
}

%headerpadlarge {
  --pad: 4em;
  padding-top: var(--pad);
  // padding-bottom: var(--pad);
  @include desktop {
    --pad: 120px;
  }
}

%full-screen {
  min-height: calc(100vh - var(--nav-height) - var(--alert-height));
  display: flex;
  align-items: center;
  justify-content: center;
}
