

%welcome-text {
  font-size: var(--headline-small-size);
  line-height: var(--headline-small-line);
  font-family: var(--sans);
  font-weight: var(--bold-weight);
}

%button-label {
  font-family: var(--sans);
  font-size: var(--size-button-label);
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}

%button-label-large {
  font-family: var(--sans);
  font-size: var(--size-button-label-large);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

// %h1 {
//   font-family: var(--serif);
//   font-size: var(--size-h1);
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1;
//   letter-spacing: normal;
// }

// %h2 {
//   font-family: var(--serif);
//   font-size: var(--size-h2);
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.33;
//   letter-spacing: normal;
// }

// %h3 {
//   font-family: var(--sans);
//   font-size: var(--size-h3);
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 0.93;
//   letter-spacing: normal;
//   color: var(--marine);
// }

%p {
  font-size: var(--body-size);
  line-height: var(--body-line);
  font-family: var(--adelle);
  font-weight: var(--thin-weight);
}

%pbold {
  font-size: var(--body-size);
  line-height: var(--body-line);
  font-weight: var(--bold-weight);
  font-family: var(--sans);
}

%pmed {
  font-family: var(--sans);
  font-size: var(--size-plarge);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
}

%plarge {
  font-family: var(--sans);
  font-size: var(--size-plarge);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: normal;
}

%resources-link {
  font-family: var(--sans);
  font-size: var(--size-resources-link);
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.18;
  letter-spacing: normal;
}

%faq-heading {
  font-family: var(--sans);
  font-size: var(--size-resources-link);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-transform: uppercase;
}

%faq-question {
  font-family: var(--sans);
  font-size: var(--size-resources-link);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: var(--marine);
}

%alert-bar-text {
  font-family: var(--sans);
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
}

%bg-header {
  font-size: var(--heading-size);
  line-height: var(--heading-line);
  color: currentColor;
  margin: 0 auto;
  font-family: var(--sans);
  font-weight: var(--bold-weight);
  text-align: left;
}

%hero-heading {
  font-size: var(--hero-heading-size);
  line-height: var(--hero-heading-line);
  font-family: var(--sans);
  font-weight: var(--bold-weight);
}

%h1 {
  font-size: var(--h1-size);
  line-height: var(--h1-line);
  font-family: var(--sans);
  font-weight: var(--semi-weight);

  @media (min-width: 992px) {
    letter-spacing: -2px;
  }
}

%h2 {
  font-size: var(--h2-size);
  line-height: var(--h2-line);
  font-family: var(--sans);
  font-weight: var(--semi-weight);

  @media (min-width: 992px) {
    letter-spacing: -2px;
  }
}

%introBody {
  font-size: var(--introBody-size);
  line-height: var(--introBody-line);
  font-family: var(--adelle);
  font-weight: var(--thin-weight);
}

%bodySmall {
  font-size: var(--body-small-size);
  line-height: var(--body-small-line);
  font-family: var(--sans);
  font-weight: var(--thin-weight);
}

%h3 {
  font-size: var(--size-h3);
  line-height: var(--h3-line);
  font-family: var(--sans);
  font-weight: var(--bold-weight);
}

%h4 {
  font-size: var(--h4-size);
  line-height: var(--h4-line);
  font-family: var(--sans);
  font-weight: var(--semi-weight);
}

%h5 {
  font-size: var(--h5-size);
  line-height: var(--h5-line);
  font-family: var(--sans);
  font-weight: var(--semi-weight);
}

%body {
  font-size: var(--body-size);
  line-height: var(--body-line);
  font-family: var(--adelle);
  font-weight: var(--thin-weight);
}

%bodyBold {
  font-size: var(--body-size);
  line-height: var(--body-line);
  font-weight: var(--bold-weight);
  font-family: var(--sans);
}

%primary-button-text {
  font-size: var(--body-semi-size);
  line-height: var(--body-semi-line);
  font-family: var(--sans);
  font-weight: var(--regular-weight);
  text-decoration: none;
}

%secondary-button-text {
  font-size: var(--secondary-button-size);
  line-height: var(--secondary-button-line);
  font-family: var(--sans);
  font-weight: var(--regular-weight);
  text-decoration: none;
}

%subheader {
  font-size: var(--subheader-size);
  line-height: var(--subheader-line);
  font-weight: var(--bold-weight);
  font-family: var(--sans);
  text-transform: uppercase;
}

%mobile-nav-links {
  font-size: var(--body-semi-size);
  line-height: var(--body-semi-line);
  font-family: var(--sans);
  font-weight: var(--regular-weight);
}

%numbers {
  font-size: var(--numbers-size);
  line-height: var(--numbers-line);
  font-weight: var(--semi-weight);
  font-family: var(--sans);
}

%breadcrumb {
  font-family: var(--sans);
  font-size: var(--subheader-size);
  line-height: var(--subheader-size);
  font-weight: var(--semi-weight);
}

%smallTileHeadline,
%blockquote {
  font-size: var(--headline-small-size);
  line-height: var(--headline-small-line);
  font-family: var(--sans);
  font-weight: var(--bold-weight);
}