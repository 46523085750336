@import "~scss/imports";

.AlertBar {
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.AlertBar-text {
  @extend %alert-bar-text;
  display: none;
  margin-right: 2em;

  @include tablet {
    display: block;
  }
}

.AlertBar-link {
  line-height: 1;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
