@import "~scss/imports";

.FullScreen {
  @extend %full-screen;
  @extend %bg-header;
  color: var(--dark-green);
  background-color: var(--white);
  text-align: center;
}

.FullScreen-inner {
  max-width: 22em;

  button {
    font: inherit;
    color: inherit;
    border: 0;
    border-radius: 0;
    text-decoration: underline;
    background-color: transparent;
  }
}
