@import "~scss/imports";

.Resources {
  list-style-type: none;
  padding: unset;
  margin: unset;
  display: grid;
  grid-gap: 3em;
}
.ResourcesItem {
  max-width: 30em;
  a {
    text-decoration: none;
  }
}

.ResourcesItem-heading {
  @extend %h5;

  a & {
    text-decoration: underline;
  }
}

.ResourcesItem-description {
  @extend %body;
  margin-top: 0.5em;
}
