@import "~scss/imports";

.TableBlock {
  padding-bottom: var(--pad-y);
  padding-top: var(--pad-y);
}

.TableBlock-wrapper {
  @extend %max;
  padding-top: var(--pad);
}

.TableBlock-heading {
  @extend %h2;
}

.TableBlock-subheading {
  @extend %h3;
}

.TableBlock-table {}

.TableBlock-tablehead {
  display: grid;

  h4 {
    @extend %h5;
    text-transform: uppercase;
  }

  &.cols-2 {
    grid-template-columns: 1fr 1fr;
  }
  &.cols-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}