@mixin desktop {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin buttonTheme($color) {
  background-color: $color;
  transition-duration: 0.3s;
  transition-property: color, background-color, border-color;

  svg {
    transition-duration: 0.3s;
    transition-property: fill, stroke;
  }

  &:hover,
  &:focus {
    outline: 0;
    background-color: var(--dark-green);
    border-color: $color;
    color: $color;
    svg {
      stroke: $color;
      fill: $color;
    }
  }
}
