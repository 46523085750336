@import "~scss/imports";

.Landing-wrapper {
  background-color: var(--green);
  color: var(--white);
  padding: var(--pad-x) var(--pad-y);
  min-height: calc(100vh - var(--nav-height) - var(--alert-height));

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

// .Landing-head {}

.Landing-header {
  @extend %h1;
  margin: 0;
}

.Landing-copy {
  @extend %welcome-text;
}

.Landing-links {
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 32px;
}

.Landing-button {
  @extend %buttonWhite;
}

.Modal-heading {
  @extend %h2;
  margin-bottom: 1rem;
}

.Modal-copy {
  @extend %p;
  margin-bottom: 44px;
}

.Modal-close {
  @extend %bodyBold;
  background: none;
  border: 0;
  color: var(--white);
  font-weight: var(--regular-weight);
  outline: 0;
  padding: 0;
  position: absolute;
  right: 32px;
  top: 32px;
}

.Modal-form-wrap {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  justify-content: flex-start;
  margin: 24px 0;

  @media (min-width: 992px) {
    max-width: 50%;
  }
}

.Modal-form-input {
  @extend %primary-button-text;
  background-color: transparent;
  border-radius: var(--corner-radius);
  border: 2px solid var(--lime);
  color: var(--white);
  display: inline-block;
  font-family: var(--sans);
  padding: 15px 20px;
  text-decoration: none;
  transition: all .3s;

  &:hover {
    background-color: var(--green);
    color: var(--white);
  }

  &:focus,
  &:focus-within {
    background-color: var(--white);
    color: var(--dark-green);
  }

  &::placeholder {
    color: var(--white);
  }
}

.Modal-form-submit {
  @extend %buttonWhite;
}

.Modal-errors {
  display: grid;
  grid-template-columns: 22px auto;
  grid-gap: 1rem;
  margin: 2rem 0;
}

.Modal-errormsg {
  @extend %mobile-nav-links;
  color: var(--gold);
  margin: 0;
}