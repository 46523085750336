@import "variables";

html {
  box-sizing: border-box;
}
* {
  box-sizing: inherit;
}

p:first-child {
  margin-top: 0;
}
p:last-child {
  margin-bottom: 0;
}

a {
  color: inherit;
}

button {
  cursor: pointer;
}

img {
  display: block;
  width: 100%;
}

.bg--white {
  background-color: var(--white);
  color: var(--dark-green);
}

.bg--lightGreen {
  background-color: var(--light-green);
  color: var(--dark-green);
}

.bg--gold {
  background-color: var(--gold);
  color: var(--dark-green);
}
