@import "~scss/imports";

.ResourcesFaq {
  @extend %max;
  @extend %headerpadlarge;
}
.ResourcesFaq-heading {
  @extend %h1;
}
.ResourcesFaq-inner {
  margin-top: 3em;
  display: grid;
  grid-gap: 3em;
  align-items: start;

  @include desktop {
    grid-template-columns: 1fr 1fr;
  }
}
