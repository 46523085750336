@import "~scss/imports";

@import "~scss/imports";

.Card-articlesWrapper {
  padding: 48px 24px 48px 24px;
}


.Card-articleCard {
  position: relative;
  filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.15));
  border-radius: var(--corner-radius);
}


.Card-icon {
  position: absolute;
  top: 10px;
  right: 16px;
  height: 25px;
  width: 25px;

  svg {
    height: auto;
    width: 100%;

    * {
      fill: var(--green)
    }
  }

  @media (min-width: 992px) {
    top: 16px;
    right: 24px;
    height: 40px;
    width: 40px;
  }
}


.Card-articlesInnerWrapper {
  max-width: var(--content-max-width);
  margin: 0 auto;
}

.Card-articlesHeader {
    padding-bottom: 48px;
    color: currentColor;

    h3 {
        @extend %h4;
        text-align: center;
    }

    h3 + p {
        padding-top: 8px;
    }

    p {
        @extend %bodySmall;
    }
}

.Card-cardWrapper {
    row-gap: 48px;
    display: grid;
}

.Card-singleCard {
    margin: 0 auto;
}

.Card-articleCard,
.Card-singleCard {
    min-height: var(--articleCard-min-height);
    background-color: var(--white);
    border-radius: var(--corner-radius);
    filter: drop-shadow(0 0 0.25rem rgba(0,0,0,0.15));

    a {
        text-decoration: none;
        color: var(--dark-green);
        display: grid;
        height: 100%;
    }
}

.Card-cardInner {
    display: grid;
    grid-template-areas:
    "top"
    "image"
    "title"
    "filler"
    "date"
    "publisher"
    "author";

    grid-template-rows: auto auto auto 1fr auto auto auto;

}

.Card-articleCard:last-child {
    margin-bottom: 0px;
}

.Card-cardTop {
    grid-area: top;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 24px;

    p {
      // @extend %numbers;
      font-size: 16px;
      letter-spacing: 0;
      color: var(--green);
    }
}

.Card-articleImg {
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.Card-articleImageWrapper {
  grid-area: image;
  height: var(--related-article-img-height);
  overflow: hidden;
}

.Card-cardTitle,
.Card-cardDate,
.Card-cardPublisher,
.Card-cardAuthor {
    margin: 0px 24px;
}

.Card-cardTitle {
  @extend %smallTileHeadline;
  grid-area: title;
  min-height: 150px;
  padding: 16px 0px;
}

.Card-cardAuthor,
.Card-cardDate,
.Card-cardPublisher {
  @extend %bodySmall;
  align-items: center;
  border-top: 2px solid var(--lime);
  display: flex;
  min-height: var(--body-small-min-height);
}

.Card-cardDate {
  grid-area: date;
}

.Card-cardPublisher {
  grid-area: publisher;
}

.Card-cardAuthor {
  grid-area: author;
}


// Medium Sized devices
@media (min-width: 768px) and (max-width: 991px) {

    .Card-cardWrapper {
        grid-template-columns: repeat(2, 1fr);
        gap: 48px 49px;
    }

    .Card-singleCard {
        width: 53%;
    }

}

// Large Sized Devices and Up
// Desktop Styling
@media (min-width: 992px) {

    .Card-cardWrapper {
        gap: 48px 49px;
        grid-template-columns: repeat(3, 1fr);
    }

    .Card-articlesWrapper {
        padding: 96px 104px 96px 104px;
    }

    .Card-articlesHeader {
        padding-bottom: 96px;

        h3 + p {
            padding-top: 32px;
        }

        p {
            font-size: var(--body-size);
            line-height: var(--body-line);
        }

    }

    .Card-cardTop {
        padding: 24px 24px 30px;
    }
}

//Extra large Devices uses 3 columns
@media (min-width: 1300px) {
    .Card-cardWrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}