@import "~scss/imports";

.AlertReadMore {
  background-color: var(--gold);
  color: var(--dark-green);
  padding: 48px 24px;
}

.AlertReadMore-inner {
  @extend %maxslim;

  display: grid;
  grid-template-areas:
    "heading"
    "text"
    "remaining"
    "button";

  grid-gap: 1em;

  @include tablet {
    grid-template-areas: "heading text" "heading remaining" "heading button";
    grid-template-columns: 1fr minmax(auto, 637px);
    grid-gap: 2rem;

    .AlertReadMore-heading {
      justify-self: end;
      text-align: right;
      border-right: 2px solid;
      border-bottom: 0;
      margin-right: 0;
      padding-right: 2rem;
      padding-left: 1rem;
      min-width: 7em;
    }
  }
}

.AlertReadMore-heading {
  @extend %h4;
  grid-area: heading;
  border-bottom: 2px solid;
  padding-bottom: 0.5rem;

  padding-right: 1em;
  margin-right: 1em;
}

.AlertReadMore-initialText {
  grid-area: text;
  @extend %bodySmall;
}

.AlertReadMore-toggle {
  @extend %buttonDark;
  align-items: center;
  display: flex;
  grid-area: button;
  justify-self: start;

  svg {
    margin-left: 0.75em;
    width: 1em;

    path {
      stroke: var(--dark-green);
      transition: stroke 0.2s;
    }
  }

  &:hover {
    svg path { stroke: var(--white); }
  }

  &.open svg {
    transform: rotate(180deg);
  }
}

.AlertReadMore-remainingText {
  grid-area: remaining;
}
