@import "~scss/imports";

.YoutubeModal-button {
  color: inherit;
  @extend %button;
  display: flex;
  align-items: center;
  background-color: transparent;

  svg {
    width: 31px;
    margin-left: 1em;
    fill: var(--marine);
  }
}

.VideoModal {
  &:focus {
    outline: 0;
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.25);
  }
  position: relative;
  .VideoEmbed {
    background-color: black;
  }
}
.VideoModal-close {
  position: absolute;
  right: 0;
  transform: translateY(calc(-100% - 0.5em));
  background-color: transparent;
  border: 0;
  border-radius: 0;
  font: inherit;
}
