@import "~scss/imports";

.TableRow {
  border-top: 2px solid currentColor;
  padding: var(--pad) 0;
  display: grid;
  grid-gap: 1rem;

  @include desktop {
    grid-gap: 0;

    &.cols-2 {
      grid-template-columns: 1fr 1fr;
    }
    &.cols-3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

.TableRow-content {
  p {
    @extend %body;
    margin: 0;
  }

  h4 {
    @extend %h4;
    margin: 0;
  }
}

.TableRow-label {
  @extend %h4;
  margin: 0;
}
.TableRow-copy {
  @extend %body;
  margin: 0;
}