@import "~scss/imports";

.CopyBlock {
  padding-top: var(--pad-y);
  padding-bottom: var(--pad-y);
}

.CopyBlock-wrapper {
  padding: var(--pad);
  max-width: var(--body-max-width);
  margin: 0 auto;
}

.CopyBlock-heading {
  @extend %h2;
  margin-top: 0;
}

.CopyBlock-copy {
  @extend %body;

  h2 {
    @extend %h2;
  }
  h3 {
    @extend %h3;
  }
  h4 {
    @extend %h4;
  }
  h5 {
    @extend %h5;
  }
}