@import "~scss/imports";

.Blog-grid {
  list-style: none;
  margin: 0;
  padding: 24px 24px 48px;
  row-gap: 48px;
  display: grid;

  @media (min-width: 768px) {
    padding: 0 0 96px;
    gap: 48px 48px;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 104px;
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
