@import "~scss/imports";

.EmbedBlock {
  padding-top: var(--pad-y);
  padding-bottom: var(--pad-y);
}

.EmbedBlock-wrapper {
  padding: var(--pad);
  max-width: var(--body-max-width);
  margin: 0 auto;
}

.EmbedBlock-heading {
  @extend %h2;
  margin-top: 0;
}

.EmbedBlock-copy {
  @extend %body;

  h2 {
    @extend %h2;
  }
  h3 {
    @extend %h3;
  }
  h4 {
    @extend %h4;
  }
  h5 {
    @extend %h5;
  }
}

.EmbedBlock-embed {
  display: block;
  width: 100%;

  iframe,
  figure {
    display: block;
    margin: 0;
    width: 100%;
  }

  iframe {
    width: 100%;
    min-height: 700px;
  }
}
