@import "~scss/imports";

.Search {
  @extend %max;
  padding-top: var(--pad);
  padding-bottom: var(--pad);
}

.Search-form-wrap {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  margin-top: var(--pad-y);

  @media (min-width: 768px) {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 200px;
    grid-template-areas:
      "label label"
      "input submit";
    grid-column-gap: 2rem;
  }
}

.Search-form-input {
  @extend %primary-button-text;
  background-color: transparent;
  border-radius: var(--corner-radius);
  border: 2px solid var(--lime);
  color: var(--green);
  display: inline-block;
  font-family: var(--sans);
  padding: 15px 20px;
  text-decoration: none;
  transition: all .3s;
  width: 100%;

  grid-area: input;

  @media (min-width: 768px) {
    width: auto;
  }

  // &:hover {
  //   background-color: var(--green);
  //   color: var(--white);
  // }

  &:focus,
  &:focus-within {
    background-color: var(--white);
    color: var(--dark-green);
    outline-color: var(--green);
  }

  &::placeholder {
    color: var(--green);
  }
}

.Search-form-label {
  @extend %bodySmall;
  grid-area: label;
  color: var(--green);

}

.Search-form-submit {
  @extend %button;
  grid-area: submit;
}

.Search-results {
  margin: 2rem 0;
  padding-left: 1rem;
  list-style: none;

  > li {
    border-bottom: 1px solid var(--dark-green);
    margin: 1.5rem 0;
    padding: 1.5rem 0 3rem;
  }
}

.Search-result-link {
  text-decoration: none;

  figure, img, ul {
    display: none;
  }

  &:hover {
    .Search-result {
      text-decoration: underline;
    }
  }
}

.Search-result {
  @extend %h4;
  padding: 0;
  text-decoration: none;
}

.Search-snippet {
  margin-top: 1rem;
  text-decoration: none;

  * {
    border: none;
    font-family: var(--sans) !important;
    margin: 0;
    padding: 0;
  }

  .TableRow-label,
  .TableRow-content h4,
  .richTextField h4 strong,
  .richTextField h4 {
    @extend %pbold;
  }
}