@import "~scss/imports";

.LandingHeader-inner {
  // padding: 48px 24px;
  // color: var(--dark-green);
  // grid-gap: 16px;

  // display: grid;
  // grid-template-areas:
  //   "superheading"
  //   "heading"
  //   "subheading"
  //   "text"
  //   "body"
  // ;
  // grid-template-columns: 1fr;

  // @media (min-width: 992px) {
  //   grid-template-areas:
  //   "superheading superheading"
  //   "heading subheading"
  //   "text body"
  // ;
  // grid-template-columns: 1fr 1fr;
  // }
  @extend %max;
  @extend %headerpad;
  display: grid;

  grid-template-areas:
    "image"
    "superheading"
    "heading"
    "text"
    "link";
  grid-gap: 24px;

  .LandingHeader-link {
    margin-top: 0.5em;
  }

  @include desktop {
    grid-template-areas:
      "superheading image"
      "heading image"
      "text image"
      "link image";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto 1fr;
    grid-column-gap: 6vw;

    .LandingHeader-link {
      margin-top: 1em;
    }
  }
  @media (min-width: 1200px) {
    grid-column-gap: 8vw;
  }
}

.LandingHeader-superheading {
  grid-area: superheading;
  @extend %breadcrumb;
  border-bottom: 2px solid var(--lime);
  justify-self: flex-start;
  padding-bottom: 2px;
}

.LandingHeader-heading {
  grid-area: heading;
  @extend %h1;
  position: relative;

  &:before {
    background-color: var(--lime);
    content:"";
    height: 2px;
    left: 50%;
    position: absolute;
    top: calc(100% + 16px);
    bottom: 0;
    transform: translateX(-50%);
    width: calc(100vw - 48px);
  }

  @include desktop {
    &:before {
      background-color: var(--lime);
      content:"";
      height: 2px;
      left: calc(100% + 15px);
      position: absolute;
      top: calc(100% + 30px);
      width: calc(200% + 30px);
    }
  }
}

.LandingHeader-text {
  grid-area: text;
  @extend %welcome-text;
  color: var(--green);
  @include desktop {
    margin-top: 50px;
  }
}

.LandingHeader-link {
  grid-area: link;
}

.LandingHeader-image {
  grid-area: image;
  margin-bottom: 2em;

  @include desktop {
    margin-bottom: 0;
  }
  video {
    width: 100%;
    display: block;
    position: relative;
    z-index: -1;
  }
}
