@import "~scss/imports";

.richTextField {
  p,
  strong,
  ul,
  li,
  ol,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: currentColor;
  }

  a {
    color: currentColor;
    text-decoration: none;
    transition: border-bottom .3s;
    border-bottom: 2px solid currentColor;

    &:hover {
      border-bottom: 2px solid transparent;
    }
  }

  > h1:first-child,
  > h2:first-child,
  > h3:first-child,
  > h4:first-child,
  > h5:first-child {
    margin-top: 0;
  }

  p,
  ul,
  li,
  ol {
    @extend %body;
  }

  a {

  }

  h1 {
    @extend %h1;

    strong {
      @extend %h1;
    }
  }
  h2 {
    @extend %h2;

    strong {
      @extend %h2;
    }
  }
  h3 {
    @extend %h3;

    strong {
      @extend %h3;
    }
  }
  h4 {
    @extend %h4;

    strong {
      @extend %h4;
    }
  }
  h5 {
    @extend %h5;

    strong {
      @extend %h5;
    }
  }

  strong {
      @extend %pbold;
  }

  blockquote {
    @extend %blockquote;
    line-height: 32px;
    color: currentColor;
    border-top: 2px solid var(--lime);
    border-bottom: 2px solid var(--lime);
    padding: 24px 0px;
    margin: 48px 0px;
  }

  p + p {
    padding-top: 32px;
  }

  h3 + p {
    margin-top: 1rem;
  }

  figure {
      margin: 0px;
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
    width: auto;
  }

  figure {
      iframe {
          max-width: var(--body-max-width);
          width: 100%!important;
      }
  }
}


@media (min-width: 992px) {

  .richTextField {

      blockquote {
          padding: 40px 0px;
          margin: 72px 0px;
      }
  }
}