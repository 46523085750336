@import "~scss/imports";
.App {
  color: var(--dark-green);
  padding-top: calc(var(--nav-height) + var(--alert-height));
}
.sticky-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.AlertLanguage {
  background-color: var(--green);
  min-height: var(--alert-height);
  display: flex;
  width: 100%;

  .AlertBar {
    justify-content: flex-start;
  }
  @include desktop {
    padding-left: var(--language-selector-width);
    .AlertBar {
      justify-content: center;
    }
  }
  .GoogleTranslate {
    margin-left: auto;
  }
}

.AlertLanguage-inner {
  @extend %max;
  display: flex;
  width: 100%;
}
