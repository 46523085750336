@import "mixins";

@import url("https://use.typekit.net/uqj0axw.css");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:wght@300;400&display=swap');


:root {
  --adelle: 'Roboto Serif', serif;
  --sans: "acumin-pro", sans-serif;
}

:root {

  --light-green: #ECF2C9;
  --lime: #93C13E;
  --dark-green: #002B00;
  --dark-green-2: #001F00;
  --green: #005B2C;
  --teal: #007B4D;
  --gold: #FFC63E;
  --brass: #C48F0E;
  --wine: #7D113E;
  --orange: #F58220;

  --white: #ffffff;
  --cerulean: var(--green);
  --dark-cerulean: var(--green);
  --marine: var(--dark-green);
  --light-sky-blue: #cceaf7;
  --pale: #fff3d8;
  --very-light-pink: #ffe0e0;
  --light-peach: #ffc2c2;
  --pale-salmon: #ffa3a3;
  --peachy-pink: #ff8585;
  --salmon: #ff6666;
  --eggshell-blue: #cdf7ec;
  --light-aqua: #9befd9;
  --pale-peach: #ffe7b1;
  --wheat: var(--gold);
  --sand-yellow: var(--gold);
  --butterscotch: var(--gold);
  --overlay: rgba(231, 231, 231, 0.5);
}

:root {
  --nav-height: 80px;
  --max-pad: 1em;
  --pad: 24px;
  --pad-x: 24px;
  --pad-y: 24px;
  --alert-height: 40px;
  --language-selector-width: 224px;

  --corner-radius: 8px;

  @include tablet {
    --max-pad: 6em;
    --pad-x: 48px;
    --pad-y: 24px;
  }

  @include desktop {
    --max-pad: 80px;
    --pad-x: 104px;
    --pad-y: 96px;
  }
}

:root {

  /* Navigation */
  --nav-max-width: 1232px;
  /* --alert-height: 30px; */

  --content-max-width: 1232px;
  --body-max-width: 720px;

  /* Photo & Body Copy Sizes */
  --bg-photo-max-width: 1200px;
  --introBody-max-width: 720px;
  --header-bg-max-width: 1040px;
  --inline-photo-max-width: 688px;
  --body-max-width: 720px;
  --header-max-width: 720px;
  --nav-card-min-width: 592px;

  /* Font & Line Height Sizes */

  --body-semi-size: 18px;
  --body-semi-line: 18px;

  --body-medium-size: 18px;
  --body-medium-line: 18px;

  --extra-bold-weight: 800;
  --bold-weight: 700;
  --semi-weight: 600;
  --regular-weight: 500;
  --thin-weight: 400;

  --numbers-size: 14px;
  --numbers-line: 20px;

  --body-small-size: 16px;
  --body-small-line: 26px;


  --footer-link-height: 72px;

  --adelle: 'Roboto Serif', serif;
  --adelle-sans: "acumin-pro", sans-serif;

  --sans: "acumin-pro", sans-serif;

  --corner-radius: 8px;

  --size-welcome-heading: 40px;
  --size-h1: 42px;
  --size-h2: 32px;
  --size-h3: 26px;
  --size-pmed: 24px;
  --size-welcome-superheading: 22px;
  --size-plarge: 22px;
  --size-welcome-text: 20px;
  --size-p: 18px;
  --size-resources-link: 18px;
  --size-button-label: 14px;
  --size-button-label-large: 20px;
  --size-mobile-nav-links: 24px;

  @media (min-width: 600px) {
    --size-mobile-nav-links: 30px;
  }

  @media (min-width: 1200px) {
    --size-welcome-heading: 80px;
    --size-h1: 58px;
    --size-h2: 36px;
    --size-h3: 32px;
    --size-welcome-superheading: 26px;
    --size-plarge: 26px;
    --size-resources-link: 22px;
  }
}


@media (min-width: 320px) {

  :root{
    // --alert-height: 30px;

    // --nav-height: 124px;
    // --nav-height-collapsed: 80px;
    // --report-nav-height: 60px;

    --halfPage-photo-max-width: 288px;

    --related-article-img-height: 160px;

    --body-size: 16px;
    --body-line: 26px;

    --heading-size: 24px;
    --heading-line: 30px;

    --h1-size: 42px;
    --h1-line: 42px;

    --h2-size: 24px;
    --h2-line: 29px;

    --h3-size: 16px;
    --h3-line: 26px;

    --h4-size: 28px;
    --h4-line: 36px;

    --h5-size: 22px;
    --h5-line: 26px;

    --hero-heading-size: 24px;
    --hero-heading-line: 30px;

    --headline-small-size: 18px;
    --headline-small-line: 26px;

    --headline-size: 24px;
    --headline-line: 30px;

    --introBody-size: 16px;
    --introBody-line: 26px;

    --subheader-size: 14px;
    --subheader-line: 22px;

    --primary-button-height: 96px;
    --primary-button-width: 224px;
    --primary-button-size: 16px;
    --primary-button-line: 20px;
    --primary-button-weight: 800;

    --secondary-button-height: 80px;
    --secondary-button-width: 208px;
    --secondary-button-size: 16px;
    --secondary-button-line: 20px;

    --body-small-min-height: 48px;

    --articleCard-max-width: 288px;
    --articleCard-min-height: 514px;

    --nav-card-height: 334px;
  }
}

@media(min-width: 768px) {
  :root{
    --alert-height: 30px;
    --report-nav-height: 75px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

  :root{
    // --alert-height: 54px;

    // --nav-height: 168px;
    // --nav-height-collapsed: 100px;

    // --report-nav-height: 90px;

    --heading-size: 42px;
    --heading-line: 54px;

    --headline-size: 30px;
    --headline-line: 40px;

    --hero-heading-size: 60px;
    --hero-heading-line: 58px;

    --headline-small-size: 22px;
    --headline-small-line: 30px;

    --h1-size: 76px;
    --h1-line: 76px;

    --h2-size: 48px;
    --h2-line: 58px;

    --h3-size: 20px;
    --h3-line: 26px;

    --related-article-img-height: 210px;

    --introBody-size: 21px;
    --introBody-line: 32px;

    --subheader-size: 18px;
    --subheader-line: 22px;

    --halfPage-photo-max-width: 616px;

    --body-size: 18px;
    --body-line: 30px;

    --primary-button-height: 88px;
    --primary-button-width: 288px;
    --primary-button-size: 16px;
    --primary-button-line: 18px;
    --primary-button-weight: 800;

    --secondary-button-height: 72px;
    --secondary-button-width: 288px;
    --secondary-button-size: 18px;
    --secondary-button-line: 18px;

    --body-small-min-height: 48px;

    --articleCard-max-width: 378px;
    --articleCard-min-height: 616px;

    --nav-card-height: 380px;

  }

}
