@import "~scss/imports";

.Blog-contentWrapper {
  max-width: var(--body-max-width);
  margin-left: auto;
  margin-right: auto;
  padding: 48px 24px 0;

  >a {
      text-decoration: none;
  }
}

.Blog-typeHandle {
  display: flex;
  align-items: center;
  text-decoration: none;
  grid-gap: 1rem;

  &:hover {
    // .typeName {
    //   border-color: var(--white);
    // }

    svg {
      left: -30px;
    }
  }

  @media (min-width: 992px) {
    position: relative;

    svg {
      position: absolute;
      left: -24px;
      top: 2px;
      transition: all 0.2s;
    }
  }
}


.Blog-typeName {
  @extend %breadcrumb;
  color: var(--dark-green);
  display: inline-block;
  border-bottom: 2px solid var(--lime);
  padding-bottom: 4px;
  transition: border 0.2s;
  text-decoration: none;
}

.Blog-articleHeader {
    @extend %h2;
    color: var(--dark-green);
}

.Blog-details {
    border-top: 2px solid var(--lime);
    border-bottom: 2px solid var(--lime);
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    min-height: var(--body-small-min-height);
}

.Blog-date, .Blog-publisher {
    @extend %bodySmall;
    min-height: var(--body-small-min-height);
    display: flex;
    align-items: center;
    color: var(--dark-green);
    margin: 0;
}

.Blog-date {
    border-bottom: 2px solid var(--lime);
}

.Blog-authors {
    display: flex;
    border-bottom: 2px solid var(--lime);
    color: var(--dark-green);
    min-height: var(--body-small-min-height);
    align-items: center;
}

.Blog-articleContentWrapper {
    margin: 0px auto 48px auto;
}

.Blog-image {
  margin: 48px 0px 0px 0px;
  padding: 0;
}

.Blog-blogImage {

    img {
        height: auto;
        width: 100%;
        display: block;
    }
}

.Blog-caption {
    color: var(--dark-green);
    @extend %bodySmall;
    padding-top: 24px;
}

.Blog-articleContent {
  padding-top: 48px;
}

.Blog-blogBtn {
  margin: 48px auto 0px auto;
}


@media (min-width: 992px) {

  .Blog-blogBtn {
    margin-top: 56px;
  }

  .Blog-contentWrapper {
    padding-top: 112px;
  }

  .Blog-articleContent {
    padding-top: 56px;
  }

  .Blog-image {
    margin-top: 56px;
  }


  .Blog-details {
    align-items: center;
    border-bottom: 2px solid var(--lime);
    border-top: 2px solid var(--lime);
    flex-direction: row;
    min-height: var(--body-small-min-height);
  }

  .Blog-authors {
    align-items: center;
    border-bottom: 2px solid var(--lime);
    display: flex;
    min-height: var(--body-small-min-height);
  }

  .Blog-date, .Blog-publisher {
    min-height: auto;
    margin: 0;
  }

  .Blog-date {
    border-bottom: none;
    border-right: 2px solid var(--lime);
    padding-right: 22px;
  }

  .Blog-publisher {
    padding-left: 24px;
  }

  .Blog-articleContentWrapper {
    margin: 0px auto 96px auto;
  }
}
