@import "~scss/imports";

.Footer-top {
  background-color: var(--green);
  color: var(--white);
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 4em;
  padding: var(--pad-x) var(--pad-y);

  @include desktop {

    grid-template-columns: 20% 1fr 1fr;

    .Footer-info {
      padding-top: 0.5em;
      padding-left: 2.5em;
      padding-right: 2.5em;
    }
  }
}

.Footer-heading {
  @extend %h4;
}

.Footer-info {
  border-left: 2px solid var(--lime);
  padding-left: 1em;
  padding-right: 1em;
  overflow-x: auto;
  overflow-y: hidden;
  @include tablet {
    overflow: unset;
  }
}

.Footer-info-heading {
  font-family: var(--sans);
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}


.Footer-info-text p,
.Footer-info-text a {
  font-family: var(--sans);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

.Footer-info-text {
  margin-top: 2em;

  .richTextField a {
    border: none;
    text-decoration: underline;
  }

  .richTextField p + p {
    padding-top: 0;
  }
}

.Footer-bottom {
  background-color: var(--dark-green);

  a:focus {
    outline: 1px solid var(--white);
  }
}

.Footer-bottom-inner {
  @extend %max;
  color: var(--white);

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 50px var(--pad-y);
  text-align: center;
}

.Footer-bottom-inner-logo {
  max-width: 240px;

  @include desktop {
    max-width: 282px;
  }
}
