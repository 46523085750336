@import "~scss/imports";

.Nav {
  background-color: var(--light-green);
  color: var(--dark-green);
}

.Nav-inner {
  height: var(--nav-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @extend %max;

  > a {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    img {
      max-width: 130px;
      @include tablet {
        max-width: 282px;
      }
    }
  }
}

.Nav-links {
  @extend %mobile-nav-links;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 2rem;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 75%;
  z-index: 1;

  a {
    color: inherit;
    display: block;
    padding-bottom: 5px;
    position: relative;
    text-decoration: none;

    &:after {
      background-color: transparent;
      border-radius: 5px;
      bottom: 0;
      content: "";
      height: 2px;
      left: 0;
      position: absolute;
      right: 0;
    }

    &:hover {
      color: var(--dark-green);
    }
  }

  a.active:after {
    background-color: var(--lime);
  }
}

.MobileNav {
  --toggle-size: 40px;
}
.MobileNav-toggleph {
  width: var(--toggle-size);
  height: var(--toggle-size);
  margin-left: 1em;
}
.MobileNavToggle {
  background-color: transparent;
  border: 0;
  border-radius: 0;

  &:focus {
    outline: 2px solid var(--dark-green);
  }

  .MobileNav.active & {
    position: fixed;
  }
  z-index: 1;
  width: var(--toggle-size);
  height: var(--toggle-size);
  padding: 7px;
}

.MobileNav-links {
  list-style-type: none;
  padding: 5rem 0;
  margin: 0;
  text-align: left;
  @extend %mobile-nav-links;

  position: fixed;
  margin-top: 0;
  top: var(--alert-height);
  left: 0;
  width: 100%;
  height: calc(100vh - var(--alert-height));
  overflow-y: auto;

  display: flex;
  flex-direction: column;

  background-color: var(--white);

  // li:first-child {
  //   margin-top: auto;
  // }
  // li:last-child {
  //   margin-bottom: auto;
  // }

  li + li {
    margin-top: 2em;
  }

  .navItem {
    padding: 0 1rem;
  }
}

.navItem {
  @extend %mobile-nav-links;
  border: 0;
  background-color: transparent;
  padding: 0;
  text-decoration: underline;
  text-align: left;
}

.listItem.has-children {
  position: relative;

  &:hover {
    .dropdown {
        height: auto;
    }
  }
}

.dropdown-wrapper {
  list-style: none;
  min-width: 100%;
  padding: 2px 0;
}

.dropdown {
  align-items: center;
  border-top: 24px solid transparent;
  display: flex;
  left: -12px;
  min-width: 190px;
  overflow: hidden;
  padding: 1rem 0;
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100% + 24px);
  z-index: -1;

  &.closed {
    height: 0;
    padding: 0;
  }

  &.open {
    height: auto;
    overflow: visible;

    .dropdown-wrapper {
      background-color: var(--light-green);
    }
  }
}

.dropdown-item {
  align-self: center;

  a {
    border: 0;
  }

  .dropdown-navItem {
    padding: 1rem 2rem;
    transition: all 0.3s;

    &:hover {
      background-color: var(--white);
    }
  }
}

.dropdown-navItem {
  @extend %mobile-nav-links;
  color: var(--white);
  display: block;
  font-weight: var(--regular-weight);
  text-decoration: none;
}

.Mobile-dropdown-wrapper  {
  list-style: none;
  padding: 1rem;
}

.Mobile-dropdown {
  background-color: var(--green);
  overflow: hidden;
}

.MobileNav-links .Mobile-dropdown-item {
  margin-top: 1rem;

  &:first-of-type {
    margin-top: 0;
  }
}

.Mobile-dropdown-navItem {
  color: var(--white);
  text-decoration: none;
}