@import "~scss/imports";

.ButtonLink {
  text-decoration: none;
  @extend %button;
  display: inline-flex;
  align-items: center;

  svg {
    margin-left: 0.5em;
    stroke: var(--marine);
    * {
      stroke: inherit;
    }
  }
}
