#google_translate_element {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}
.skiptranslate {
  display: none !important;
}
body {
  position: unset !important;
}
.GoogleTranslate {
  max-width: var(--language-selector-width);
  width: 100%;
  display: flex;
  align-items: stretch;
  > div {
    width: 100%;
  }
}
.GoogleTranslate-reset {
  border: 0;
  background-color: transparent;
  color: var(--white);

  svg * {
    stroke: var(--white);
  }
}
