@import "~scss/imports";

%overview-heading {
  font-family: var(--sans);
  font-size: 34px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-transform: uppercase;
}
%platform-heading {
  font-family: var(--sans);
  font-size: 36px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}

.OverviewPlatform {
  --pad: 4em;
  display: grid;
  @extend %max;
  align-items: center;
  grid-gap: var(--pad);
  padding-top: var(--pad);
  padding-bottom: var(--pad);

  @include tablet {
    grid-template-columns: 1fr 1fr;
    grid-gap: 4vw;
    --pad: 8vw;
  }
}
.Overview {
  max-width: 30em;
}

.Overview-heading {
  @extend %h4;
  margin-bottom: 1rem;
}

.Overview-text {
  @extend %p;
}

.Platform {
  display: grid;
  justify-items: start;
}
.Platform-label {
  background-color: var(--dark-cerulean);
  // border-radius: var(--corner-radius);
  color: var(--white);
  padding: 0.5em 2rem;
  text-transform: uppercase;
}
.Platform-inner {
  border: 2px solid var(--dark-cerulean);
  // border-radius: var(--corner-radius);
  padding: 2em 2rem 2.5em;
  display: grid;
  grid-gap: 1em;
}
.Platform-heading {
  @extend %platform-heading;
  color: var(--cerulean);
}
.Platform-text {
  @extend %p;
}
.Platform-link {
  @extend %pbold;
}
