@import "~scss/imports";

.PageHeader {
  color: var(--dark-green);
}

.PageHeader-inner {
  @extend %headerpadlarge;
  @extend %max;
  // padding-bottom: var(--pad);
  // display: grid;

  // grid-template-areas:
  //   "image"
  //   "heading"
  //   "text"
  //   "link";
  // grid-gap: 2em;

  // @include tablet {
  //   grid-template-areas:
  //     "heading image"
  //     "text image"
  //     "link link";
  //   grid-template-columns: 4fr 4fr;
  //   grid-template-rows: auto auto 1fr;
  //   grid-column-gap: 0;
  //   //align-items: center;
  //   // padding-left: 4vw;
  //   // padding-right: 4vw;
  // }

  // @include desktop {
  //   grid-template-areas:
  //     "heading image"
  //     "text image"
  //     "link image";
  //   grid-template-columns: 1fr 1fr;
  //   grid-template-rows: auto auto 1fr;
  //   grid-column-gap: 0;
  //   align-items: start;
  // }

  color: var(--dark-green);
  grid-gap: 16px;

  display: grid;
  grid-template-areas:
    "heading"
    "text"
  ;
  grid-template-columns: 1fr;

  @media (min-width: 992px) {
    grid-template-areas:
    "heading "
    "text "
  ;
  grid-template-columns: 1fr 1fr;
  }
}

.PageHeader-heading {
  @extend %h1;
  grid-area: heading;
  margin: 0;
  position: relative;

  &:before {
    background-color: var(--lime);
    content:"";
    height: 2px;
    left: 50%;
    position: absolute;
    top: calc(100% + 16px);
    bottom: 0;
    transform: translateX(-50%);
    width: calc(100vw - 48px);
  }

  @include desktop {
    &:before {
      background-color: var(--lime);
      content:"";
      height: 2px;
      left: calc(100% + 15px);
      position: absolute;
      top: calc(100% + 50px);
      width: calc(200% + 30px);
    }
  }
}

.PageHeader-text {
  @extend %welcome-text;
  grid-area: text;
  color: var(--green);
  @include desktop {
    margin-top: 50px;
  }
}

.PageHeader-link {
  @extend %button;
  justify-self: flex-start;
}

.PageHeader-image {
  grid-area: image;
}

.PageHeader + .bg--gold,
.PageHeader + .bg--lightGreen {
  padding-top: 0;
  margin-top: 33px;
  position: relative;
  // z-index: 2;

  &:before {
    content: "";
    left: 0;
    right: 0;
    height: var(--pad-y);
    background-color: white;
    position: relative;
    top: 0px;
    width: 100%;
    display: block;
  }
}